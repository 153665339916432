/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { ChartJobTableQuery } from "~/generated/graphql";
import {
  ChartType as CostAnalysisChartType,
  CostAnalysisFields,
  generateLinkParameters,
  getCostAnalysisTableColumns,
  Granularity,
  LinkQueryParameterName,
  mapStackBarChartColors,
  Period,
  TableData,
} from "~/components";
import { useCurrency } from "~/hooks";
import { isNotNil } from "~/tools";
import {
  getExtraCostDetails,
  reorderGroups,
} from "~/views/estate/components/Charts/helpers";
import { ChartJobData } from "../types";
import {
  generateSavingPlanCommitments,
  generetaEstateRecordsGroups,
  getCategories,
} from "./helpers";

type Props = {
  chartData: ChartJobData | undefined;
  tableData: ChartJobTableQuery | undefined;
  granularity: Granularity;
  period: Period;
  startDate: string;
  endDate: string;
};

export function useEstateRecordsCostPerApplication({
  chartData,
  tableData,
  granularity,
  period,
  startDate,
  endDate,
}: Props) {
  const { currency } = useCurrency();

  const granularData = useMemo(
    () =>
      granularity === Granularity.DAYS
        ? chartData?.chartJob?.data
        : chartData?.chartMonthlyJob?.data,
    [chartData, granularity]
  );

  const costPerApplicationChartData = useMemo(
    () => generateSavingPlanCommitments(granularData?.timePoints ?? []),
    [granularData]
  );

  const applicationsCostTableTimePoints = useMemo(
    () => (tableData?.chartJobTable?.data?.results ?? []).filter(isNotNil),
    [tableData]
  );

  const costPerApplicationTotal = Number(
    chartData?.chartJob?.data?.total ?? "0"
  );

  const applications = getCategories(
    chartData?.chartJob?.data?.timePoints ?? []
  );

  const costPerApplicationTableData: TableData[] = useMemo(() => {
    const showTotalRow = period !== Period.MONTH;
    return generetaEstateRecordsGroups(
      applicationsCostTableTimePoints ?? [],
      showTotalRow
    );
  }, [applicationsCostTableTimePoints, period]);

  const tableCount = tableData?.chartJobTable?.data?.count;

  const reoderedApplications = reorderGroups(
    applications,
    CostAnalysisFields.OTHER_APPLICATIONS_COST
  );

  const chartKeys = useMemo(
    () => [...reoderedApplications, CostAnalysisFields.SAVINGS_PLANS],
    [reoderedApplications]
  );

  const stackBarColors = useMemo(
    () => mapStackBarChartColors(chartKeys),
    [chartKeys]
  );

  const allColumns = useMemo(
    () =>
      getCostAnalysisTableColumns({
        startDate,
        endDate,
        currency,
        data: costPerApplicationTableData,
        period,
        chartType: CostAnalysisChartType.COST_PER_APPLICATION,
        colors: stackBarColors,
        linkParameters: generateLinkParameters(
          LinkQueryParameterName.APPLICATION,
          []
        ),
        headerName: "Application Name",
      }),
    [
      startDate,
      endDate,
      currency,
      costPerApplicationTableData,
      period,
      stackBarColors,
    ]
  );

  const extraDetails = getExtraCostDetails(
    chartData?.chartJob?.data?.extraDetails ?? []
  );

  return {
    costPerApplicationChartData,
    costPerApplicationTableData,
    costPerApplicationTotal,
    tableCount,

    extraDetails,
    allColumns,
    stackBarColors,
    chartKeys,
  };
}
