/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useToggle } from "react-use";
import styled from "styled-components";
import {
  FlexContainer,
  theme,
  Text,
  MultipleSelect,
  SelectButton,
} from "@nordcloud/gnui";
import { SUCCESS_TEXT, ERROR_TEXT } from "~/constants";
import { showSimpleInfo, showError } from "~/services/toast";
import { AwsAutoForm } from "./AwsAutoForm";
import { AwsManualForm } from "./AwsManualForm";
import { useUpdateAwsBillingData } from "./hooks/useUpdateAwsBillingData";
import { AwsFormFields, FormData } from "./validators";

export function AwsBillingDataUpdateForm({
  accountId,
  close,
}: {
  accountId: string;
  close: () => void;
}) {
  const [updateAwsBillingData] = useUpdateAwsBillingData();
  const [isFormManual, toggleIsFormManual] = useToggle(false);

  const AwsForm = isFormManual ? AwsManualForm : AwsAutoForm;

  const handleUpdate = async (data: FormData) => {
    try {
      await updateAwsBillingData(data);
      showSimpleInfo(SUCCESS_TEXT.billingDataUpdated);
      close();
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  return (
    <FlexContainer
      marginTop={theme.spacing.spacing04}
      direction="column"
      alignItems="flex-start"
      gap={theme.spacing.spacing01}
    >
      <Text weight="bold">Billing Data Settings</Text>
      <MultipleSelect>
        <SelectButton
          name="auto"
          value="auto"
          labelText="Automatic (Recommended)"
          isActive={!isFormManual}
          onClick={toggleIsFormManual}
        />
        <SelectButton
          style={{ paddingInline: "6rem" }}
          name="manual"
          value="manual"
          labelText="Manual"
          isActive={isFormManual}
          onClick={toggleIsFormManual}
        />
      </MultipleSelect>
      <Seperator />
      <AwsForm
        closeForm={close}
        defaultValues={{ accountId }}
        disabledFields={[AwsFormFields.ACCOUNT_ID]}
        onSubmit={handleUpdate}
      />
    </FlexContainer>
  );
}

const Seperator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.color.border.input};
  margin-bottom: ${theme.spacing.spacing02};
  margin-top: ${theme.spacing.spacing02};
`;
