/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { FlexContainer, theme, SVGIcon, Box, Text } from "@nordcloud/gnui";
import { CloudProviderType } from "~/generated/graphql";
import { Provider } from "~/tests/types";
import { CloudAccountStatus, isNotNil, Maybe } from "~/tools";
import { getProviderIconName } from "../../CloudAccountList/utils";
import { AccountStatusIcon } from "../../components/AccountStatusIcon";

type DetailsHeaderProps = {
  accountName: string;
  provider: Provider;
  providerType: Maybe<CloudProviderType>;
  cloudStatus?: CloudAccountStatus;
};

export function DetailsHeader({
  accountName,
  provider,
  providerType,
  cloudStatus,
}: DetailsHeaderProps) {
  return (
    <FlexContainer
      pb={theme.spacing.spacing04}
      css={{ borderBottom: theme.borders.grey }}
      gap={theme.spacing.spacing03}
    >
      <IconWrap>
        <AccountStatusIcon status={cloudStatus} size="lg" />
      </IconWrap>
      {isNotNil(provider) && (
        <IconWrap>
          <SVGIcon
            size="lg"
            name={getProviderIconName(provider, providerType ?? undefined)}
          />
        </IconWrap>
      )}
      <div>
        <Text size="sm" tag="div" color={theme.color.text.text03}>
          Account name
        </Text>
        <Text tag="div">{accountName}</Text>
      </div>
    </FlexContainer>
  );
}

const IconWrap = styled(Box)`
  padding: 0;
  background-color: ${theme.color.background.ui03};
  border: ${theme.borders.grey};
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
