/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import {
  ChartJobTableTimePoint,
  ChartTableSortField,
  ChartTableSortOrder,
  SearchJobStatus,
} from "~/generated/graphql";
import { CostAnalysisFields, EstatePeriod, TableData } from "~/components";
import { isNotNil } from "~/tools";
import { mapEstatePeriodToBillingPeriod } from "../../helpers";
import { BillingPeriod } from "../../types";
import { ChartTimePoint } from "../types";

export function getSelectedBillingPeriod(selectedPeriod: EstatePeriod) {
  if (selectedPeriod !== EstatePeriod.RANGE) {
    return mapEstatePeriodToBillingPeriod(selectedPeriod);
  }

  return undefined;
}

export function getBillingPeriod(
  isPreviousBillingPeriod: boolean,
  queryStatePeriod?: BillingPeriod
) {
  if (queryStatePeriod) {
    return mapEstatePeriod(queryStatePeriod === BillingPeriod.Previous);
  }

  return mapEstatePeriod(isPreviousBillingPeriod);
}

export function mapEstatePeriod(isPreviousBillingPeriod: boolean) {
  return isPreviousBillingPeriod
    ? EstatePeriod.PREVIOUS_MONTH
    : EstatePeriod.CURRENT_MONTH;
}

export function isJobCompleted(status?: SearchJobStatus): boolean {
  return status === SearchJobStatus.Completed;
}

export function isJobFailed(status?: SearchJobStatus): boolean {
  return status === SearchJobStatus.Failed;
}

export function getDefaultSortOrderByFilter(
  filter: ChartTableSortField
): ChartTableSortOrder {
  const isDefaultDescending = filter === ChartTableSortField.Cost;

  return isDefaultDescending
    ? ChartTableSortOrder.Dsc
    : ChartTableSortOrder.Asc;
}

export function getCategories(timePoints: ChartTimePoint[]) {
  const categories = timePoints
    .flatMap((timePoint) =>
      timePoint?.groups
        ?.filter((group) => Number(group?.value) !== 0)
        .flatMap((category) => category?.name ?? "")
    )
    .filter(isNotNil);

  return [...new Set(categories)];
}

export function generetaEstateRecordsGroups(
  timePoints: ChartJobTableTimePoint[],
  showTotalRow: boolean
) {
  const data = generateTableData(timePoints, showTotalRow);
  const groups = generateGroups(timePoints);

  return data.map((key) => ({
    ...key,
    id: groups.find((group) => group.name === key.field)?.id ?? key.id,
  }));
}

function generateTableData(
  timePoints: ChartJobTableTimePoint[],
  showTotalRow: boolean
): TableData[] {
  const keys = [
    ...new Set(
      timePoints
        .flatMap((timePoint) =>
          timePoint?.groups?.flatMap((group) => group?.name ?? "")
        )
        .filter(isNotNil)
    ),
  ];

  const totalCostPerPeriod = Object.fromEntries(
    timePoints.map(({ date, total }) => [date, Number(total)])
  );

  const generatedData = keys.filter(isNotNil).map((key) => ({
    field: key,
    total: timePoints
      .flatMap((timePoint) => timePoint.groups)
      .map((item) => (item?.name === key ? Number(item?.cost) : 0))
      .reduce((a, b) => a + Number(b), 0),
    ...Object.fromEntries(
      timePoints.map(({ date }) => [
        date,
        Number(
          timePoints
            .find((item) => item?.date === date)
            ?.groups?.find((item) => item?.name === key)?.cost ?? 0
        ),
      ])
    ),
  }));

  const total = timePoints.reduce((a, b) => a + Number(b.total), 0);

  return [
    ...(showTotalRow
      ? [
          {
            id: CostAnalysisFields.TOTAL_COST,
            field: "",
            ...totalCostPerPeriod,
            total,
          },
        ]
      : []),
    ...generatedData,
  ];
}

function generateGroups(timePoints: ChartJobTableTimePoint[]) {
  const keys = [
    ...new Set(
      timePoints
        .flatMap((timePoint) =>
          timePoint?.groups?.flatMap((group) => group?.name ?? "")
        )
        .filter(isNotNil)
    ),
  ];

  return keys.map((key) => {
    const groupId =
      timePoints
        .flatMap((timePoint) => timePoint.groups)
        .find((group) => group?.name === key)?.key ?? "";

    return {
      id: groupId,
      name: key,
    };
  });
}

export function generateSavingPlanCommitments(timepoints: ChartTimePoint[]) {
  const chartTimePoints = timepoints.filter(isNotNil);

  return chartTimePoints.map((timepoint) => {
    if (dayjs(timepoint.date).isAfter(dayjs(), "day")) {
      return {
        ...timepoint,
        groups: [
          {
            id: "savings",
            value: timepoint.value,
            name: CostAnalysisFields.SAVINGS_PLANS,
          },
        ],
      };
    }
    return {
      ...timepoint,
    };
  });
}
