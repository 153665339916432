/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { ChartJobTableQuery } from "~/generated/graphql";
import {
  ChartType as CostAnalysisChartType,
  CostAnalysisFields,
  generateLinkParameters,
  getCostAnalysisTableColumns,
  Granularity,
  LinkQueryParameterName,
  mapStackBarChartColors,
  Period,
  TableData,
} from "~/components";
import { useCurrency } from "~/hooks";
import { isNotNil } from "~/tools";
import { getExtraCostDetails } from "~/views/estate/components/Charts/helpers";
import { ChartJobData } from "../types";
import {
  generateSavingPlanCommitments,
  generetaEstateRecordsGroups,
  getCategories,
} from "./helpers";

type Props = {
  chartData: ChartJobData | undefined;
  tableData: ChartJobTableQuery | undefined;
  granularity: Granularity;
  period: Period;
  startDate: string;
  endDate: string;
};

export function useEstateRecordsCostPerProvider({
  chartData,
  tableData,
  granularity,
  period,
  startDate,
  endDate,
}: Props) {
  const { currency } = useCurrency();

  const granularData = useMemo(
    () =>
      granularity === Granularity.DAYS
        ? chartData?.chartJob?.data
        : chartData?.chartMonthlyJob?.data,
    [chartData, granularity]
  );

  const costPerProviderChartData = useMemo(
    () => generateSavingPlanCommitments(granularData?.timePoints ?? []),
    [granularData]
  );

  const providersCostTableTimePoints = useMemo(
    () => (tableData?.chartJobTable?.data?.results ?? []).filter(isNotNil),
    [tableData]
  );

  const costPerProviderTotal = Number(chartData?.chartJob?.data?.total ?? "0");

  const providers = getCategories(chartData?.chartJob?.data?.timePoints ?? []);

  const costPerProviderTableData: TableData[] = useMemo(() => {
    const showTotalRow = period !== Period.MONTH;
    return generetaEstateRecordsGroups(
      providersCostTableTimePoints ?? [],
      showTotalRow
    );
  }, [providersCostTableTimePoints, period]);

  const tableCount = tableData?.chartJobTable?.data?.count ?? 0;

  const chartKeys = useMemo(
    () => [...providers, CostAnalysisFields.SAVINGS_PLANS],
    [providers]
  );

  const stackBarColors = useMemo(
    () => mapStackBarChartColors(chartKeys),
    [chartKeys]
  );

  const allColumns = useMemo(
    () =>
      getCostAnalysisTableColumns({
        startDate,
        endDate,
        currency,
        data: costPerProviderTableData,
        period,
        chartType: CostAnalysisChartType.COST_PER_PROVIDER,
        colors: stackBarColors,
        linkParameters: generateLinkParameters(
          LinkQueryParameterName.PROVIDER,
          []
        ),
        headerName: "Provider",
      }),
    [
      startDate,
      endDate,
      currency,
      costPerProviderTableData,
      period,
      stackBarColors,
    ]
  );

  const extraDetails = getExtraCostDetails(
    chartData?.chartJob?.data?.extraDetails ?? []
  );

  return {
    costPerProviderChartData,
    costPerProviderTableData,
    costPerProviderTotal,
    tableCount,
    extraDetails,
    allColumns,
    stackBarColors,
    chartKeys,
  };
}
