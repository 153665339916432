/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  ChartTableSortField,
  ChartTableSortOrder,
  Maybe,
} from "~/generated/graphql";
import { Granularity } from "~/components";
import { BillingPeriod } from "../types";

export enum EstateGroupBy {
  NONE = "None",
  APP = "Application",
  ORG = "Organizational Unit",
  ENV = "Environment Type",
  PROVIDER = "Provider",
  // Not implemented yet
  // SERVICE = "Service",
  // REGION = "Region",
  // ACCOUNT = "Cloud Account",
  // CATEGORY = "Category",
}

export const estateGroupByOptions = Object.values(EstateGroupBy).map(
  (item) => ({
    value: item,
    label: item,
  })
);

export type EstateChartsQueryState = {
  periodStart?: string;
  periodEnd?: string;
  groupBy?: EstateGroupBy;
  granularity?: Granularity;
  billingPeriod?: BillingPeriod;
};

export type GroupTimePoint = {
  id: string;
  name: string;
  value: string;
} | null;

export type ChartTimePoint = {
  date: string;
  value: string;
  groups?: Maybe<GroupTimePoint[]>;
} | null;

export type ChartJobData = {
  chartJob?: Maybe<{
    data?: Maybe<{
      total: string;
      timePoints?: Maybe<ChartTimePoint[]>;
      extraDetails?:
        | ({
            name: string;
            value: string;
          } | null)[]
        | null;
    }>;
  }>;
  chartMonthlyJob?: Maybe<{
    data?: Maybe<{
      total: string;
      timePoints?: Maybe<ChartTimePoint[]>;
    }>;
  }>;
};

export type ChartData = {
  total: string;
  timePoints?: Maybe<ChartTimePoint[]>;
};

export type ExtraChartDetails = ({ name: string; value: string } | null)[];

export type EstateChartTableQueryState = {
  field?: ChartTableSortField;
  order?: ChartTableSortOrder;
  periodStart?: string;
  periodEnd?: string;
  query?: string;
};
