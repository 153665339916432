/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { When } from "react-if";
import {
  Input,
  Label,
  Message,
  Spacer,
  theme,
  Text,
  Button,
  FlexContainer,
} from "@nordcloud/gnui";
import { ExternalLink, FormGroup, FormHint } from "~/components";
import { useCustomers } from "~/services/customers";
import {
  FormData,
  AwsFormFields,
  AwsFormSchema,
} from "~/views/cloudAccounts/BillingData/aws/validators";
import {
  cloudFormationStackLink,
  cloudFormationStackLinkLite,
  awsAutoFormDefaultValues,
} from "../constants";

type Props = {
  closeForm: () => void;
  onSubmit: SubmitHandler<FormData>;
  showRegionDisclaimer?: boolean;
  defaultValues?: Partial<FormData>;
  disabledFields?: (keyof FormData)[];
};

export function AwsAutoForm({
  onSubmit,
  closeForm,
  showRegionDisclaimer,
  defaultValues,
  disabledFields = [],
}: Props) {
  const formMethods = useForm<FormData>({
    resolver: yupResolver(AwsFormSchema),
    mode: "onChange",
    defaultValues: { ...awsAutoFormDefaultValues, ...defaultValues },
  });

  const { isAdminLite } = useCustomers();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form
        css={{ width: "100%" }}
        id="auto-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <When condition={showRegionDisclaimer}>
          <Message status="notification" image="info">
            The CloudFormation stack will be deployed in the US-EAST-1 region.
          </Message>
        </When>
        <Spacer height={theme.spacing.spacing04} />
        <FormHint stepNumber={1}>
          <Text>
            Download and deploy{" "}
            <ExternalLink
              href={
                isAdminLite
                  ? cloudFormationStackLinkLite
                  : cloudFormationStackLink
              }
            >
              CloudFormation stack
            </ExternalLink>{" "}
            in your organization&apos;s account.
          </Text>
        </FormHint>
        <FormHint stepNumber={2}>
          <Text>
            Complete the information below to access your billing information.
          </Text>
        </FormHint>
        <Spacer height={theme.spacing.spacing02} />
        <FormGroup error={errors[AwsFormFields.ACCOUNT_ID]}>
          <Label
            required
            htmlFor={AwsFormFields.ACCOUNT_ID}
            name="Account ID"
          />
          <Input
            disabled={disabledFields.includes(AwsFormFields.ACCOUNT_ID)}
            id={AwsFormFields.ACCOUNT_ID}
            {...register(AwsFormFields.ACCOUNT_ID)}
          />
        </FormGroup>
        <FormGroup error={errors[AwsFormFields.BUCKET_ID]}>
          <Label
            required
            htmlFor={AwsFormFields.BUCKET_ID}
            name="Bucket Name"
          />
          <Input
            disabled={disabledFields.includes(AwsFormFields.BUCKET_ID)}
            id={AwsFormFields.BUCKET_ID}
            {...register(AwsFormFields.BUCKET_ID)}
          />
        </FormGroup>
        <FlexContainer gap={theme.spacing.spacing03}>
          <Button type="submit" icon="checkmark">
            Apply
          </Button>
          <Button severity="low" onClick={closeForm}>
            Cancel
          </Button>
        </FlexContainer>
      </form>
    </FormProvider>
  );
}
